@use 'common'

$base-original-width: 565px
$base-original-height: 218px
$eye-original-width: 49px
$eye-original-height: 54px

.wrapper
	position: relative
	display: flex
	width: 100%
	min-width: 200px

	&::before
		content: ''
		display: block
		padding-top: common.div(100%, (common.div($base-original-width, $base-original-height)))

.base,
.eyeIn
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	object-fit: contain

.eye
	position: absolute
	top: 30%
	left: 20.9%
	width: common.div($eye-original-width, $base-original-width) * 100%
	transform: translate(calc(var(--WatchingLogo-offset-x) * 18%), calc(var(--WatchingLogo-offset-y) * 52%))

	&::before
		content: ''
		display: block
		padding-top: common.div(100%, (common.div($eye-original-width, $eye-original-height)))

	& + &
		left: 35.9%
