@use './common'

$topMenuHeight: 53px
$zIndexBase: 12

.wrapper
	border-bottom: 2px solid #2E2E2E
	text-align: center
	position: relative

.main
	display: grid
	grid-template-columns: 1fr auto 1fr

	&.view_isOpen
		.button
			&:hover, &:focus
				transform: rotate(90deg)

			&::before
				transform: rotate(-45deg)

			&::after
				transform: rotate(45deg)

		.navigation
			&Menu
				visibility: visible
				opacity: 1

			&Logo
				background-color: transparent

.button, .navigationLogo
	position: relative
	z-index: $zIndexBase + 2

.button
	$buttonSize: 25px
	$buttonSpacing: 5px

	align-self: center
	display: flex
	flex-direction: column
	justify-content: space-evenly
	position: relative
	width: $buttonSize
	height: $buttonSize
	background: 0
	border: 0
	padding: $buttonSpacing
	cursor: pointer
	transition: transform .3s

	&:hover, &:focus
		outline: 0
		transform: rotate(180deg)

	&::before, &::after
		content: ''
		position: absolute
		top: 50%
		transform: translateY(-50%)
		width: $buttonSize - (2 * $buttonSpacing)
		height: 2px
		background-color: #000000
		transition: transform .3s

	&::before
		transform: translateY(2px)

	&::after
		transform: translateY(-4px)

.navigation

	&Menu
		position: fixed
		top: 0
		right: 0
		bottom: 0
		left: 0
		visibility: hidden
		opacity: 0
		transition-property: visibility, opacity
		transition-duration: .3s
		background-color: common.$c-beige
		z-index: $zIndexBase + 1

		&In
			overflow-y: auto
			overflow-x: hidden
			position: absolute
			top: $topMenuHeight + 10px // $topMenuHeight = see top, 10px = spacing
			left: 0
			right: 0
			bottom: 0

		&Secondary
			margin-top: 25px

			@media (min-width: common.$break1100)
				margin-top: 0

		&Tags
			margin-top: 50px

			&RowGrid
				margin: 30px 0

		&Primary

			@media (min-width: common.$break1100)
				display: none

	&Logo
		max-width: 200px
		font-size: 59px
		margin: 0
		display: flex
		align-items: center
		transition: background-color .3s

		&:hover, &:focus
			outline: 0

	&Primary

		&Left, &Right
			display: none

			@media (min-width: common.$break1100)
				display: flex
				align-items: center
				height: 100%
				padding-left: 24px
				padding-right: 24px

		&Left
			justify-self: end

		&Right
			justify-self: start

	@media (min-width: common.$break1100)
		display: grid
		grid-template-columns: 1fr auto 1fr
		justify-items: center
		align-items: center

.headerRight
	justify-self: end
	align-self: center
	color: inherit
	display: flex
	align-items: center

.userCircle
	width: 24px
	height: 24px
	border-radius: 50%
	border: 2px solid #000
	background-color: common.$c-orange
	text-align: center
	z-index: 12
	span
		color: #fff
		line-height: 1
		text-transform: uppercase
		font-weight: 700
		font-family: common.$f-montreal
		font-size: 14px
	a
		text-decoration: none

.userIcon
	border: none
	padding: 0
	background-color: transparent
	color: #000
	display: flex
	align-items: center
	justify-content: center
