@use 'common'

.wrapper
	display: grid
	grid-template-columns: auto 1fr
	column-gap: 5px
	justify-items: start

	&.view_reversed
		grid-template-columns: 1fr auto
		grid-auto-flow: dense
		justify-items: end

		.photo
			grid-column: 2

	&.is_sender
		.message
				background-color: common.$c-white
				box-shadow: 0px 0px 10px -5px rgba(#000000, .3),  0px 1px 1px 0 rgba(#000000, .05)
				color: common.$c-dark
				--Textual-color: common.$c-dark

.photo
	position: relative
	width: 20px
	height: 20px
	margin-top: auto
	border-radius: 50%
	overflow: hidden

.message
	--Textual-spacing-coef: 0
	--Textual-color: common.$c-white
	--Textual-font-size-base: 16px

	padding: 6px 12px
	color: common.$c-white
	background-color: common.$c-blue
	border-radius: 15px
