@use 'common'

$maxIconSize: 30px

.wrapper
	background-color: common.$c-blue-light
	border-radius: 20px
	padding: 30px 15px

.item

	&:not(:first-child)
		padding-top: 12px

.header
	display: flex
	justify-content: space-between
	align-items: center
	margin-bottom: 30px

	&Title
		margin: 0
		padding: 0 5px
		font-size: 25px
		text-align: center
		text-transform: uppercase
		font-weight: 900

	&Icon
		max-width: $maxIconSize
		flex-shrink: 0
