@use "common"

$itemHorizontalSpacing: 15px
$itemVerticalSpacing: 10px

.wrapper
	background-color: #2e2e2e
	color: #ffffff
	padding: 30px 0 15px

	@media (min-width: common.$break700)
		padding: 70px 0 50px

.logo
	text-align: center
	font-size: 70px
	color: common.$c-green

	@media (min-width: common.$break480)
		font-size: 100px

	@media (min-width: common.$break700)
		font-size: 130px

	&.view_contemberLogo
		max-width: 500px
		margin: 0 auto

.list
	display: flex
	justify-content: center
	flex-wrap: wrap
	list-style: none
	padding: 0

.item
	padding: $itemVerticalSpacing $itemHorizontalSpacing
	font-size: common.em(13)
	font-weight: 500

.link
	text-decoration: none

	&:hover, &:focus
		outline: none
		text-decoration: underline

.copyright
	margin: .5em 0
	text-align: center
	font-size: common.em(13)
