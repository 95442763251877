.wrapper
	display: grid
	grid-template-rows: auto 1fr

.header
	padding: 3vw 0 1vw
	text-align: center
	h1
		font-size: 3vw
		font-size: clamp(2em, 3vw, 4em)

.kiosek
	#didomi-host
		display: none
		pointer-events: none
.kiosek.didomi-popup-open
	overflow: auto !important
.kiosek.didomi-popup-open-ios
	position: relative !important