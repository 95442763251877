@use 'common'

.wrapper
	@media (min-width: common.$break1100)
		height: 100%

.list
	$horizontalLabelSpacing: 17px
	display: flex
	align-items: flex-start
	justify-content: center
	list-style: none
	padding: 0
	margin: 30px -10px 0 -10px

	@media (min-width: common.$break1100)
		margin: 0 -10px
		height: 100%
		flex-direction: row
		justify-content: flex-start

		&.view_navigationSecondary
			justify-content: center
			margin-left: -1 * $horizontalLabelSpacing
			margin-right: -1 * $horizontalLabelSpacing

			.label
				font-size: 30px
				font-weight: 700
				padding-left: $horizontalLabelSpacing
				padding-right: $horizontalLabelSpacing

.label
	height: 100%
	font-size: 18px
	font-family: common.$f-montreal
	font-weight: 700

	@media (min-width: common.$break1100)
		display: flex
		align-items: center
		flex: none

.link
	padding: 5px 10px
	text-decoration: none

	&:hover, &:focus
		outline: 0
		text-decoration: underline
