@use 'common'
$spacing: 5px

.Wrapper
	display: flex
	margin: -$spacing
	justify-content: flex-start
	flex-wrap: wrap

	&.center
		justify-content: center

	&.end
		justify-content: flex-end

	.Item
		margin: $spacing
		font-size: 13px

	&.large
		.Item
			font-size: 18px
