@use './common'
.Wrapper
	--container-horizontal-spacing: var(--global-page-horizontal-spacing)
	--container-horizontal-spacing-left: calc(var(--container-horizontal-spacing) + env(safe-area-inset-left))
	--container-horizontal-spacing-right: calc(var(--container-horizontal-spacing) + env(safe-area-inset-right))

	max-width: calc(var(--container-width) + var(--container-horizontal-spacing-left) + var(--container-horizontal-spacing-right))
	width: 100%
	margin-left: auto
	margin-right: auto
	padding-left: var(--container-horizontal-spacing-left)
	padding-right: var(--container-horizontal-spacing-right)

	&.View_size_narrow
		--container-width: var(--global-content-width-narrow)

	&.View_size_normal
		--container-width: var(--global-content-width-normal)

	&.View_size_wide
		--container-width: var(--global-content-width-wide)

	&.View_size_full
		max-width: none

	&.View_disableGutters
		--container-horizontal-spacing: 0px

	&.View_disableGuttersMobile
		@media(max-width: common.$break480)
			overflow: hidden
			max-width: 100%
			--container-horizontal-spacing: 0px

	&[data-contember-type="reference"]
		margin-top: 2em
		margin-bottom: 2em
