@use "../styles/common"

.wrapper
	padding: 1em 1em 5em
	margin: auto 0

	@media (min-width: common.$break700)
		display: grid
		grid-auto-flow: dense
		grid-template-columns: auto auto
		column-gap: 60px
		justify-content: center

.content
	text-align: center

.title
	font-size: 60px
	margin: 0

.text
	margin: 0
	margin-top: 10px

	&:first-of-type
		margin-top: 0
		font-size: 22px

.button
	margin-top: 30px

.infographic
	margin: 0 auto
	margin-bottom: 40px
	max-width: 350px

	@media (min-width: common.$break700)
		margin: 0
		grid-column: 2
