@use 'common'
$spacing: 15px

.wrapper
	text-align: center
	margin: ($spacing * -1) 0
	font-size: 13px

	.row
		display: flex
		margin: 0 ($spacing * -1)
		justify-content: center
		flex-wrap: wrap
		&.view_size_large
			font-size: 40px
		&.view_size_medium
			font-size: 30px
		&.view_size_small
			font-size: 24px
		&.view_size_extraSmall
			font-size: 18px

	.item
		margin: $spacing

	&.small
		$spacing: 5px

		margin: ($spacing * -1) 0
		.item
			margin: $spacing


.title
	margin: 20px 0 10px
	text-transform: uppercase
	font-size: 14px
	font-weight: 400

@media(max-width: common.$break480)
	.nav
		.row
			&.view_size_large
				font-size: 18px
