@use './common'

.wrapper
	display: flex
	margin-right: 10px
	align-items: center

.form
	width: 300px
	position: relative
	opacity: 0
	pointer-events: none
	transition: all .35s ease
	background-color: #fff
	@media(max-width: common.$break768)
		position: absolute
		bottom: 0
		left: 0
		width: 100%
		//height: 100vh
		//background-color: common.$c-beige
		z-index: 10
		padding: 0
		transform: translateY(100%)
		input
			padding: 10px !important
	&.visible
		opacity: 1
		pointer-events: auto
	input
		width: 100%
		border: none
		font-family: common.$f-epilogue
		-webkit-appearance: none
		appearance: none
		background-color: common.$c-beige
		z-index: 11
		position: relative
		padding: 10px 10px 8px 10px
		border-radius: 10px
		font-size: 16px
		line-height: 1em
		&::-webkit-search-cancel-button
			display: none
		&:focus
			outline: none
	&.resultsVisible
		input:focus
			border: none
			border-radius: 10px 10px 0 0
	input:focus + .results
		opacity: 1
		pointer-events: auto

.articleInner, .authorInner
	display: flex
	align-items: center

.articleImage, .authorImage
	position: relative
	width: 50px
	height: 50px
	border-radius: 3px
	overflow: hidden
	flex-shrink: 0

.articleTitle, .authorTitle
	padding: 0
	margin: 0
	font-size: 14px
	text-align: left
	text-decoration: none
	margin-left: 10px
	transition: opacity .35s ease

.results
	position: absolute
	bottom: 2px
	left: 0
	width: 100%
	transform: translateY(100%)
	z-index: 10
	margin: 0
	background-color: common.$c-beige
	//border: 1px solid common.$c-light-grey
	border: none
	list-style: none
	border-radius: 0 0 10px 10px
	padding: 0
	opacity: 0
	//pointer-events: none
	transition: opacity .35s ease
	li
		a
			text-decoration: none
			&:hover
				.articleTitle, .authorTitle
					opacity: .7
	li + li
		margin-top: 10px
	&.hasResults
		padding: 10px

.button
	width: 25px
	height: 25px
	display: flex
	justify-content: center
	align-items: center
	border: none
	padding: 0
	background-color: transparent
	z-index: 12
	svg
		width: 14px
		height: 14px

.authorsHeadline
	text-align: left
	font-size: 16px
	padding: 0
	margin: 20px 0
	text-transform: uppercase
