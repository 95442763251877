@use 'common'
@use 'sass:math'

.Wrapper
	display: inline-block

	.Link
		$fontSize: 13px
		$verticalPadding: 5px
		$horizontalPadding: 8px

		display: flex
		align-items: center
		line-height: 1
		font-family: common.$f-montreal
		color: var(--Tag-color)
		text-decoration: none
		padding: common.em($verticalPadding, $fontSize) common.em($horizontalPadding, $fontSize)
		border-radius: common.em($fontSize + 2 * $verticalPadding, 2px)
		font-size: inherit
		background-color: var(--Tag-background-color)
		transition: transform .35s ease

		.icon
			$baseHeight: 1em

			height: $baseHeight
			width: calc(var(--Tag-icon-aspectRatio) * #{$baseHeight})
			margin-right: .2em

		&:hover, &:focus
			transform: translateY(-2px)

.bigSidePadding
	$fontSize: 13px
	$verticalPadding: 5px
	$horizontalPadding: 16px
	padding: common.em($verticalPadding, $fontSize) common.em($horizontalPadding, $fontSize) !important
